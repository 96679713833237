import React from "react"
import Layout from "../components/layout"
import Link from "gatsby-link"

const ThankYouPage = () => (
  <Layout>
    <div className="my-40 text-center font-hairline">
      <h1 className="text-3xl mb-12 text-green">Message sent.</h1>
      <p className="text-xl mb-5">Thank you for your submission!</p>
      <div className="mx-center text-xl underline"><Link to="/">Go Back</Link></div>
    </div>
  </Layout>
)

export default ThankYouPage
